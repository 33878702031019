import React from 'react';
import Ecosystem from 'components/pages/Ecosystem';
import { graphql, useStaticQuery } from 'gatsby';

const EcosystemPage = () => {
  const DATAS = useStaticQuery(graphql`
    query {
      newAgeMedia: allEcosystemJson(
        filter: { category: { eq: "New Age Media Commerce" } }
      ) {
        edges {
          node {
            id
            slug
            displayImage
            logoImage
          }
        }
      }
      omniChannel: allEcosystemJson(
        filter: { category: { eq: "Omni-Channel Retail brands" } }
      ) {
        edges {
          node {
            id
            slug
            displayImage
            logoImage
          }
        }
      }
      intellectualProperty: allEcosystemJson(
        filter: { category: { eq: "Intellectual Property" } }
      ) {
        edges {
          node {
            id
            slug
            displayImage
            logoImage
          }
        }
      }
    }
  `);

  const NEW_AGE_MEDIA = DATAS.newAgeMedia.edges;
  const OMNI_CHANNEL = DATAS.omniChannel.edges;
  const intellectualProperty = DATAS.intellectualProperty.edges;

  return (
    <div>
      <Ecosystem
        newAgeMediaData={NEW_AGE_MEDIA}
        omniChannelData={OMNI_CHANNEL}
        intellectualPropertyData={intellectualProperty}
      />
    </div>
  );
};

export default EcosystemPage;
