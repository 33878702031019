import React, { useRef } from 'react';
import Pages from '../../Pages';
import SectionBanner from './components/SectionBanner';
import SectionContent from './components/SectionContent';

const Ekosistem = ({
  newAgeMediaData,
  omniChannelData,
  intellectualPropertyData,
}) => {
  const toBottomRef = useRef(null);
  return (
    <div className="text-white">
      <Pages title="FOLK - Ecosystem">
        <SectionBanner bottomRef={toBottomRef} />
        <SectionContent
          bottomRef={toBottomRef}
          newAgeMediaData={newAgeMediaData}
          omniChannelData={omniChannelData}
          intellectualPropertyData={intellectualPropertyData}
        />
      </Pages>
    </div>
  );
};

export default Ekosistem;
