import React from 'react';
import CustomContainer from 'components/CustomContainer';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

const ContentContainer = ({ data, title, description }) => (
  <div className="flex flex-col items-start md:items-center text-left md:text-center mb-24">
    <h2 className="text-2xl md:text-4xl font-semibold md:w-full">{title}</h2>
    <p className="w-full md:w-[55%] font-[200] mt-4 text-sm">{description}</p>
    <div className="flex flex-col md:flex-row justify-center gap-5 lg:gap-10 mt-8 md:mt-16 w-full cursor-pointer">
      {data.map((company, index) => (
        <div
          key={index.toString()}
          onClick={() => {
            navigate(`/ecosystem/${company?.node?.slug}`);
          }}
          style={{
            backgroundImage: `url(${company?.node?.displayImage})`,
          }}
          className="w-full md:w-[30%] h-[350px] sm:h-[320px] lg:h-[480px] relative bg-cover bg-no-repeat bg-center 
          flex justify-center items-center bg-blend-multiply md:bg-blend-normal md:hover:bg-blend-multiply bg-orange-500 transition-all"
        >
          <img
            src={company?.node?.logoImage}
            alt={company.name}
            className="w-36 md:w-52"
          />
        </div>
      ))}
    </div>
  </div>
);

const SectionContent = ({
  bottomRef,
  newAgeMediaData,
  omniChannelData,
  intellectualPropertyData,
}) => {
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');

  return (
    <div ref={bottomRef} className="bg-black min-h-screen">
      <CustomContainer>
        <div className="py-14">
          <ContentContainer
            title="New Age Media Commerce"
            description={
              isEnglish
                ? 'As On-Demand Media surpassed Traditional Media in 2018, the media landscape was substantially changed. Led by the best talents in Indonesia, FOLK Group has established 3 main pillars in media.'
                : 'Industri media mengalami shifting besar pada tahun 2018, dari Old Media menjadi On-Demand Media. Dipimpin oleh talenta terbaikIndonesia, FOLK Networks saat ini telah memiliki 3 pilar media.'
            }
            data={newAgeMediaData}
          />
          <ContentContainer
            title="Omni-Channel Retail brands"
            description={
              isEnglish
                ? 'As the top performer of Direct-to-Consumer Brands in the top 2 marketplaces in Indonesia, drSoap, Amazara, and Syca will expand by establishing stores for activation in well-known Jakarta shopping centres from  2022 through 2023. To begin with, drSoap has established its first flagship store at Senayan City Mall in June 2022.'
                : 'Sebagai Top Performance Direct-to-consumer brand di Top 2 Marketplace di Indonesia, pada tahun 2022 – 2023 drSoap, Amazara, dan Syca akan membuka puluhan Activation Store di Pusat perbelanjaan ternama Jakarta. Official Store pertama dr Soap sendiri telah berdiri di Senayan City Mall sejak Juni 2022.'
            }
            data={omniChannelData}
          />
          <ContentContainer
            title="Intellectual Property & Community"
            description={
              isEnglish
                ? 'We have created more than 20 Original IPs (Intellectual Properties) as a competitive advantage, specifically in the Creative Economy Ecosystem, ranging from lifestyle, fashion, sports, and events. Each and every FOLK Group IP is supported by its very strong community base.'
                : 'Kami telah membangun lebih dari 20 Original IP (Intellectual Property) sebagai competitive advantage khususnya di dalam ekosistem Creative Economy, mulai dari Lifestyle, Fashion, Sports, sampai Events. Seluruh IP dari FOLK Group juga di support dengan basis komunitas yang sangat kuat.'
            }
            data={intellectualPropertyData}
          />
        </div>
      </CustomContainer>
    </div>
  );
};

export default SectionContent;
