import React from 'react';
import backgroundImage from '../../../../images/ecosystem/banner-ekosistem.png';
import arrowDownIc from '../../../../images/icon/arrow-bottom-ic.svg';
import CustomContainer from '../../../CustomContainer';
import { useLocation } from '@reach/router';

const SectionBanner = ({ bottomRef }) => {
  const scrollDown = () => {
    if (bottomRef.current) {
      window.scrollTo({
        top: bottomRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');

  return (
    <div className="h-screen relative">
      <CustomContainer>
        <div className="pt-20 h-screen">
          <div className="absolute h-full w-full top-0 left-0 -z-20">
            <img
              src={backgroundImage}
              alt="background"
              className=" object-cover h-full w-full"
            />
          </div>
          <div className="h-full flex flex-col justify-center">
            <h1 className="text-4xl md:text-6xl font-medium mb-3 sm:mb-5 z-10">
              {isEnglish ? 'Ecosystem' : 'Ekosistem'}
            </h1>
            <p className="text-sm md:text-base w-[100%] sm:w-[70%] lg:w-[45%] font-extralight mb-10 z-10">
              We are proud to have grown together with these extra-mile walkers
              in our family.
            </p>
            <img
              onClick={scrollDown}
              src={arrowDownIc}
              alt="icon-arrow"
              className="cursor-pointer hidden md:block z-10 w-[25px] cursor-pointer"
            />
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

export default SectionBanner;
